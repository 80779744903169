/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */
import {
  useQuery,
  type QueryFunction,
  type QueryKey,
  type UseQueryOptions,
  type UseQueryReturnType,
} from "@tanstack/vue-query"
import { unref, type MaybeRef } from "vue"

import { customInstance, type ErrorType } from ".././client"
import type { Error, EstimatedUsageResponse } from ".././model"
import { useQueryOptions } from ".././queryOptions"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Get the estimated usage for a meterpoint
 */
export const getEstimatedEnergyUsage = (
  meterpointId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  meterpointId = unref(meterpointId)

  return customInstance<EstimatedUsageResponse>(
    {
      url: `/api/v1/meterpoints/${meterpointId}/estimatedUsage`,
      method: "GET",
      signal,
    },
    options,
  )
}

export const getGetEstimatedEnergyUsageQueryKey = (
  meterpointId: MaybeRef<number>,
) => {
  return ["api", "v1", "meterpoints", meterpointId, "estimatedUsage"] as const
}

export const useGetEstimatedEnergyUsageQueryOptions = <
  TData = Awaited<ReturnType<typeof getEstimatedEnergyUsage>>,
  TError = ErrorType<Error>,
>(
  meterpointId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getEstimatedEnergyUsage>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetEstimatedEnergyUsageQueryKey(meterpointId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEstimatedEnergyUsage>>
  > = ({ signal }) =>
    getEstimatedEnergyUsage(meterpointId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { meterpointId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getEstimatedEnergyUsage>>,
    TError,
    TData
  >
}

export type GetEstimatedEnergyUsageQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEstimatedEnergyUsage>>
>
export type GetEstimatedEnergyUsageQueryError = ErrorType<Error>

/**
 * @summary Get the estimated usage for a meterpoint
 */
export const useGetEstimatedEnergyUsage = <
  TData = Awaited<ReturnType<typeof getEstimatedEnergyUsage>>,
  TError = ErrorType<Error>,
>(
  meterpointId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getEstimatedEnergyUsage>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetEstimatedEnergyUsageQueryOptions(
    meterpointId,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
