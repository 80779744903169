<script setup lang="ts">
  import { Route } from "@/constants/route"
  import { formatDate } from "@/lib/dateHelper"
  import { useSmartPayAsYouGo } from "@/lib/useSmartPayAsYouGo"
  import dayjs from "dayjs"
  import ConfirmationLayout from "@/components/confirmation-layout/ConfirmationLayout.vue"
  import { Button } from "@/components/ui/button"
  import { Link } from "@/components/ui/link"

  const { switchDate } = useSmartPayAsYouGo()
</script>

<template>
  <ConfirmationLayout title="You're all set to switch to Smart Pay As You Go">
    <p class="mb-6 text-secondary-500 sm:mb-8">
      We'll switch your account on
      {{ formatDate(switchDate || dayjs(), "ordinal", "default") }}.
    </p>
    <p class="w-full">What happens next:</p>
    <ol class="mb-6 list-decimal pl-4 sm:mb-8">
      <li>We'll email your Smart Pay As You Go welcome pack</li>
      <li>Download our app to manage your top-ups</li>
      <li>We'll remind you 3 days before your switch</li>
      <li>
        You can top up from midnight on the day of the switch but before 10am to
        avoid self disconnecting.
      </li>
    </ol>
    <p class="mb-6">
      If you have any questions, please check our FAQs. You can also reach us
      via email at
      <Link class="link" to="mailto:help@so.energy">help@so.energy</Link> or
      chat online with an agent (available weekdays from 9 am to 5 pm).
    </p>
    <template #footer>
      <Button size="md" as-child>
        <Link :to="Route.home" data-testid="to-dashboard-btn"
          >Back to Dashboard</Link
        >
      </Button>
    </template>
  </ConfirmationLayout>
</template>
