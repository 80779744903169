import { useGetMetersByBillingAccountId } from "@/api/account/account"
import { useGetEstimatedEnergyUsageQueryOptions } from "@/api/meterpoint/meterpoint"
import { useQueries } from "@tanstack/vue-query"
import { computed } from "vue"

import { isDefined } from "./isDefined"
import { useGetAccountId } from "./useGetAccountId"

export const useGetEstimatedAnnualEnergyUsage = () => {
  const accountIdQuery = useGetAccountId()
  const metersQuery = useGetMetersByBillingAccountId(accountIdQuery.accountId)

  return useQueries({
    queries: computed(() =>
      (metersQuery.data.value ?? []).map((mp) =>
        useGetEstimatedEnergyUsageQueryOptions(mp.meterpointID),
      ),
    ),
    combine: (results) => ({
      data: results.map((result) => result.data).filter(isDefined),
      isLoading:
        accountIdQuery.isLoading.value ||
        metersQuery.isLoading.value ||
        results.some((result) => result.isLoading),
      isError:
        accountIdQuery.isError.value ||
        metersQuery.isError.value ||
        results.some((result) => result.isError),
    }),
  })
}
