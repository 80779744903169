/**
 * WARNING! DO NOT IMPORT ANYTHING IN THIS FILE
 * IT IS DEPENDED ON BY THE VERCEL MIDDLEWARE, KEEP IT DEPENDENCY-FREE
 */

const liveNexusRoutes: string[] = [
  "/myaccount/login",
  "/myaccount/maintenance",
  "/myaccount/activate-account",
  "/myaccount/set-password",
  "/myaccount/password-reset",
  "/myaccount/password-change",
  "/myaccount/usage",
]

const featureNexusRouteMapping: Record<string, string[]> = {
  VITE_USE_NEXUS_HOMEPAGE: ["/myaccount"],
  VITE_USE_NEXUS_TARIFF_PAGE: ["/myaccount/tariff"],
  VITE_USE_NEXUS_READINGS_PAGE: ["/myaccount/readings"],
  VITE_USE_NEXUS_COT_MOVE_IN: [
    "/myaccount/move-in",
    "/myaccount/move-in/move-in-address",
    "/myaccount/move-in/fdor",
    "/myaccount/move-in/personal-details",
    "/myaccount/move-in/monthly-payment",
    "/myaccount/move-in/payments",
    "/myaccount/move-in/review",
    "/myaccount/move-in-complete",
  ],
  VITE_USE_NEXUS_SPAYG: [
    "/myaccount/smart-pay-as-you-go",
    "/myaccount/smart-pay-as-you-go/why",
    "/myaccount/smart-pay-as-you-go/suitability",
    "/myaccount/smart-pay-as-you-go/choose-fuel",
    "/myaccount/smart-pay-as-you-go/switch-date",
    "/myaccount/smart-pay-as-you-go/tariff",
    "/myaccount/smart-pay-as-you-go/review",
    "/myaccount/smart-pay-as-you-go-complete",
  ],
}

function isNexusStagingHost(host: string, proxyHost: string | null): boolean {
  const isProxied = proxyHost !== host
  const nexusStagingHosts = [
    "fe-nexus.staging.soenergy.co",
    "fe-nexus.sandbox.soenergy.co",
  ]
  return nexusStagingHosts.includes(host) && !isProxied
}

/**
 * Some routes, despite being in the internal routes list, are not live or are
 * only live behind an env flag and so should not actually be considered
 * internal for the purposes of route navigation. This function will return
 * false in that case. It will obviously do the same for external routes too.
 */
export default function shouldServeNexus(
  path: string,
  currentHost: string,
  proxyHost: string | null,
  env: Record<string, string | boolean | undefined>,
) {
  if (liveNexusRoutes.includes(path)) {
    return true
  }

  const isNexusStaging = isNexusStagingHost(currentHost, proxyHost)
  const nexusPages: string[] = Object.entries(featureNexusRouteMapping)
    .filter(([flag]) => {
      return (
        /**
         * We check for bool and string since env could ne process.env if passed
         * from vercel middleware, or import.meta.env if passed from the app
         */
        env[flag] === "true" || env[flag] === true || isNexusStaging // enabling all pages on Nexus Staging for convenience
      )
    })
    .flatMap(([, pages]) => pages)

  return nexusPages.includes(path)
}
